<ion-header mode="ios" class="ion-no-border">
  <ion-toolbar>
    <ion-title>Nuova distinta</ion-title>
    <ion-icon style="float: right;" name="close-outline" (click)="modalCtrl.dismiss()"></ion-icon>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-row>
    <ion-col size="12" text-center>
      <form [formGroup]="fg">
        <ion-item mode="ios">
          <ion-input label="Codice" labelPlacement="stacked" formControlName="COD_DISTINTA"></ion-input>
        </ion-item>
        <ion-item mode="ios">
          <ion-input label="Descrizione" labelPlacement="stacked" formControlName="DES_DISTINTA"></ion-input>
        </ion-item>

      </form>
    </ion-col>
  </ion-row>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="ion-text-center">
    <ion-button mode='ios' type="submit" [disabled]="!fg.valid" (click)="doT()" color="primary">Crea</ion-button>
  </ion-toolbar>
</ion-footer>
