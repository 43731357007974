import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, ViewWillEnter } from '@ionic/angular';
import { ModelliService } from 'src/app/servizi/modelli.service';
import { modelliStruct } from 'src/app/strutture/deepspace/modelli.strutture';

@Component({
    selector: 'app-edit-modello',
    templateUrl: './edit-modello.component.html',
    styleUrls: ['./edit-modello.component.scss'],
})
export class EditModelloComponent implements OnInit {
    modello: modelliStruct;
    fg: FormGroup;

    constructor(
        public modalCtrl: ModalController,
        private ms: ModelliService,
        private fb: FormBuilder
    ) {}

    ngOnInit() {
        this.fg = this.fb.group({
            DES: [this.modello.DES],
            DATA1: [this.modello.DATA1],
            DATA2: [this.modello.DATA2],
            DATA3: [this.modello.DATA3],
            DATA4: [this.modello.DATA4],
            DATA5: [this.modello.DATA5],
            INITVAR: [this.modello.INITVAR],
            ABILITA: [this.modello.ABILITA === '1'],
        });
    }

    async onToggleChange(event: any) {
        const isChecked = event.detail.checked;
        this.fg.patchValue({ abilita: isChecked ? 1 : 0 });

        await this.ms.modModello(
            this.modello.CODMOD,
            'ABILITA',
            isChecked ? '1' : '0'
        );
    }

    async edit() {
        let modifiedFields = { field: '', value: '' };
        let hasChanges = false;

        Object.keys(this.fg.controls).forEach((controlName) => {
            const control = this.fg.get(controlName);
            if (control.dirty && control.value !== this.modello[controlName]) {
                modifiedFields.field = controlName;
                modifiedFields.value = control.value;
                hasChanges = true;
            }
        });

        // Controlla se ci sono modifiche significative (escluse quelle su "ABILITA")
        if (!hasChanges) {
            console.log('No significant changes detected.');
            return;
        }

        console.log(
            'Modified fields',
            modifiedFields.field,
            modifiedFields.value
        );

        // Esegui solo se ci sono cambiamenti rilevanti
        await this.ms
            .modModello(
                this.modello.CODMOD,
                modifiedFields.field,
                modifiedFields.value
            )
            .then(() => {
                modifiedFields = { field: '', value: '' };
            });
    }
}
