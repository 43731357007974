<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>{{'Copia testata' + ' ' + this.codstart}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <form [formGroup]="fg">

    <ion-item mode="ios">
      <ion-input formControlName="cod_end" [label]="this.translate.instant('264')" labelPlacement="stacked"></ion-input>
    </ion-item>
    <ion-item mode="ios">
      <ion-input formControlName="des_end" [label]="this.translate.instant('090')" labelPlacement="stacked"></ion-input>
    </ion-item>
    <ion-item mode="ios">
      <ion-input formControlName="diml_end" [label]="this.translate.instant('107')"
        labelPlacement="stacked"></ion-input>
    </ion-item>
    <ion-item mode="ios">
      <ion-input formControlName="dima_end" [label]="this.translate.instant('108')"
        labelPlacement="stacked"></ion-input>
    </ion-item>
    <ion-item mode="ios">
      <ion-input formControlName="dimp_end" [label]="this.translate.instant('109')"
        labelPlacement="stacked"></ion-input>
    </ion-item>

  </form>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="ion-text-center">
    <ion-button mode='ios' type="submit" [disabled]="!fg.valid" (click)="doCopia()">Copia</ion-button>
  </ion-toolbar>
</ion-footer>
