import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
    AlertController,
    ModalController,
    ViewWillEnter,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ModelliService } from 'src/app/servizi/modelli.service';
import { PersonalizzazioniArbiService } from 'src/app/servizi/personalizzazioni-arbi.service';
import { PreferenzeService } from 'src/app/servizi/preferenze.service';
import { modelliStruct } from 'src/app/strutture/deepspace/modelli.strutture';

@Component({
    selector: 'app-do-associazione',
    templateUrl: './do-associazione.component.html',
    styleUrls: ['./do-associazione.component.scss'],
})
export class DoAssociazioneComponent implements OnInit, ViewWillEnter {
    fg: FormGroup;

    modelli: modelliStruct[] = [];
    colonne: any[] = [];

    modSearch: string = '';
    colSearch: string = '';

    constructor(
        public translate: TranslateService,
        public modalCtrl: ModalController,
        private alertCtrl: AlertController,
        private psa: PersonalizzazioniArbiService,
        private ms: ModelliService,
        private ps: PreferenzeService,
        private fb: FormBuilder
    ) {}

    async ionViewWillEnter() {
        await this.getModelli();
        await this.getColonne();
    }

    ngOnInit() {
        this.fg = this.fb.group({
            codmod: ['', Validators.required],
            colplist: ['', Validators.required],
        });
    }

    async getModelli() {
        this.modelli = await this.ms.getModelli();
    }

    async getColonne() {
        let response = await this.ps.getPreferenzaCatalogo('COLONNE_PRICELIST');
        this.colonne = response.VALORE_PREFERENZA.split(',');
    }

    async doAssociazione() {
        const codmod = this.fg.get('codmod').value;
        const colplist = this.fg.get('colplist').value;

        console.log('codmod', codmod);
        console.log('colplist', colplist);
        await this.psa
            .doAssociaModelloColonnaArbi(codmod, colplist)
            .then(async () => {
                const alert = await this.alertCtrl.create({
                    mode: 'ios',
                    header: '',
                    message: 'Associazione creata!',
                    buttons: [
                        {
                            text: 'Ok',
                            role: 'confirm',
                        },
                    ],
                });
                await alert.present();
                await alert.onDidDismiss();
            });
    }
}
