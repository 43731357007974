<ion-content>

  <ion-grid>
    <ion-row>
      <ion-col class="ion-text-center">
        <img [src]="'https://api.price-list.it/images/avatar/' + utente.UUID + '.jpg'" #avatar
          (error)="avatar.hidden = true" class="useravatar">
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-text-center">
        <ion-text class="nome-cognome">{{utente.NOME + ' ' + utente.COGNOME}}</ion-text>
      </ion-col>
    </ion-row>
    <ion-row class="email-row">
      <ion-col class="ion-text-center">
        <ion-text class="email">{{utente.EMAIL}}</ion-text>
      </ion-col>
    </ion-row>
    <br>
    <div class="divider"></div>
    <br>
    <ion-row class="ion-text-center">
      <ion-col class="ion-text-center">
        <ion-item mode="ios" lines="none" class="ion-text-center" (click)="logout()">
          <ion-icon name="log-out-outline" slot="start"></ion-icon>
          <ion-label>Logout</ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row class="ion-text-center">
      <ion-col size="6" (click)="eulaModal()"
        style="text-decoration: underline; font-size: small; cursor: pointer;">Eula</ion-col>
      <ion-col size="6" (click)="privacyModal()"
        style="text-decoration: underline; font-size: small; cursor: pointer;">Privacy</ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-text-center" *ngIf="this.currentURL.includes('app.')">
        <ion-text style="font-size: small">3.0 b {{n_versione}}</ion-text>
      </ion-col>
      <ion-col class="ion-text-center"
        *ngIf="this.currentURL.includes('localhost') || this.currentURL.includes('test.')">
        <ion-text style="font-size: small">3.0 b {{n_versione}}</ion-text>
      </ion-col>
    </ion-row>
  </ion-grid>



</ion-content>
