<ion-header mode="ios" class="ion-no-border">
  <ion-toolbar>
    <ion-title>Nuova riga</ion-title>
    <ion-icon style="float: right;" name="close-outline" (click)="modalCtrl.dismiss()"></ion-icon>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-row>
    <ion-col size="12" text-center>
      <form [formGroup]="fg">
        <ion-item mode="ios">
          <ion-input label="Colonna di partenza" labelPlacement="stacked" formControlName="COL_START"></ion-input>
        </ion-item>
        <ion-item mode="ios">
          <ion-select label="Segno" labelPlacement="stacked" formControlName="SEGNO" interface="popover" mode="ios">
            <ion-select-option value="+">+</ion-select-option>
            <ion-select-option value="-">-</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item mode="ios">
          <ion-input label="Percentuale" labelPlacement="stacked" formControlName="PERC"></ion-input>
        </ion-item>
        <ion-item mode="ios">
          <ion-select label="Arrotondamento" labelPlacement="stacked" formControlName="ARROT" interface="popover"
            mode="ios">
            <ion-select-option value="0">Matematico</ion-select-option>
            <ion-select-option value="1">Eccesso</ion-select-option>
            <ion-select-option value="2">Difetto</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item mode="ios">
          <ion-input label="Colonna di destinazione" labelPlacement="stacked" formControlName="COL_DEST"></ion-input>
        </ion-item>
      </form>
    </ion-col>
  </ion-row>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="ion-text-center">
    <ion-button mode='ios' type="submit" [disabled]="!fg.valid" (click)="doR()" color="primary">Crea</ion-button>
  </ion-toolbar>
</ion-footer>
