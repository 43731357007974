<ion-header class="ion-no-border" mode='ios'>
  <ion-toolbar>
    <ion-title>{{this.translate.instant('183')}}</ion-title>
    <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
      <ion-icon slot="icon-only" name="close-circle-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="this.winnerSelected === false">

  <ion-grid>
    <ion-row class="ion-text-center">
      <ion-col>
        <ion-button mode='ios' color="secondary" class="btn" text-wrap
          (click)="articoli()">{{this.translate.instant('190')}}
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row class="ion-text-center">
      <ion-col>
        <ion-button mode='ios' color="secondary" class="btn" text-wrap
          (click)="intestazioni()">{{this.translate.instant('185')}}</ion-button>
      </ion-col>
    </ion-row>
    <ion-row class="ion-text-center">
      <ion-col>
        <ion-button mode='ios' color="secondary" class="btn" text-wrap
          (click)="winner()">{{this.translate.instant('191')}}</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-content *ngIf="this.winnerSelected === true">
  <ion-item mode="ios">
    <ion-label label="Seleziona il listino">{{this.translate.instant('113')}}</ion-label>
    <ion-select mode="ios" (ionChange)="getListinoSelezionato($event)">
      <ion-select-option *ngFor="let listino of listini">{{listino.COD_LISTINO}}</ion-select-option>
    </ion-select>
  </ion-item>
</ion-content>

<ion-footer class="ion-no-border" *ngIf="this.proseguiBtn === true">
  <ion-toolbar class="ion-text-center">
    <ion-button mode="ios" class="btn" color="primary"
      (click)="prosegui()">{{this.translate.instant('160')}}</ion-button>
  </ion-toolbar>
</ion-footer>
