/* eslint-disable @typescript-eslint/naming-convention */
export class modelliStruct {
    public CODMOD: string;
    public DES: string;
    public DATA1: string;
    public DATA2: string;
    public DATA3: string;
    public DATA4: string;
    public DATA5: string;
    public INITVAR: string;
    public ABILITA: string;

    constructor(
        CODMOD: string,
        DES: string,
        DATA1: string,
        DATA2: string,
        DATA3: string,
        DATA4: string,
        DATA5: string,
        INITVAR: string,
        ABILITA: string
    ) {
        this.CODMOD = CODMOD;
        this.DES = DES;
        this.DATA1 = DATA1;
        this.DATA2 = DATA2;
        this.DATA3 = DATA3;
        this.DATA4 = DATA4;
        this.DATA5 = DATA5;
        this.INITVAR = INITVAR;
        this.ABILITA = ABILITA;
    }
}
