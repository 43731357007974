<ion-header class="ion-no-border" mode='ios'>
  <ion-toolbar>
    <ion-icon id="backIcon" color="secondary" name="chevron-back-outline" routerDirection="root"
      [routerLink]="['/dashboard']"></ion-icon>
    <ion-title>{{this.translate.instant('182')}}</ion-title>
    <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
      <ion-icon slot="icon-only" name="close-circle-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <form>
          <ion-item mode="ios">
            <ion-label position="stacked">{{this.translate.instant('187')}}</ion-label>
            <ion-input labelPlacement="stacked" type="file" accept=".txt, .xls, .xlsx"
              (change)="importaFile($event)"></ion-input>
          </ion-item>
        </form>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="this.sceltaListino === true">
      <ion-col size="12">
        <ion-select interface="popover" placeholder="Seleziona il listino" (ionChange)="selezionaListino($event)">
          <ion-select-option [value]="listino" *ngFor="let listino of listini">{{ listino.COD_LISTINO
            }}</ion-select-option>
        </ion-select>
      </ion-col>
    </ion-row>


  </ion-grid>



</ion-content>

<ion-footer class="ion-no-border" *ngIf="showBtn === true">
  <ion-row>
    <ion-col size="12" text-center>
      <ion-button mode='ios' color="primary" (click)="prosegui()">{{this.translate.instant('160')}}</ion-button>
    </ion-col>
  </ion-row>
</ion-footer>
