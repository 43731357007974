/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { modelliStruct } from 'src/app/strutture/deepspace/modelli.strutture';
import { CommonFunctionService } from './common-function.service';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class ModelliService {
    constructor(
        private common: CommonFunctionService,
        private alertCtrl: AlertController,
        private translate: TranslateService
    ) {}

    async setModello(modello: modelliStruct) {
        const set = await this.common.setModello(modello);
        console.log('response set modello', set);
        if (set['RESULT'] === '200') {
            const alert = await this.alertCtrl.create({
                mode: 'ios',
                header: '',
                message: 'Modello creato correttamente',
                buttons: [
                    {
                        text: 'Ok',
                        role: 'confirm',
                    },
                ],
            });
            await alert.present();
            await alert.onDidDismiss();
        } else {
            const alert = await this.alertCtrl.create({
                mode: 'ios',
                header: 'Attenzione',
                message: this.translate.instant(set['RESULT']),
                buttons: [
                    {
                        text: 'Ok',
                        role: 'confirm',
                    },
                ],
            });
            await alert.present();
            await alert.onDidDismiss();
        }
    }

    async getModelli() {
        const modelli = await this.common.getModelli();
        // console.log('get modelli', modelli);
        return modelli;
    }

    async modModello(codmod: string, field: string, value: string) {
        const set = await this.common.modModello(codmod, field, value);
        console.log('response set modello', set);
        if (set['RESULT'] === '200') {
            const alert = await this.alertCtrl.create({
                mode: 'ios',
                header: '',
                message: 'Modello modificato',
                buttons: [
                    {
                        text: 'Ok',
                        role: 'confirm',
                    },
                ],
            });
            await alert.present();
            await alert.onDidDismiss();
        } else {
            const alert = await this.alertCtrl.create({
                mode: 'ios',
                header: 'Attenzione',
                message: this.translate.instant(set['RESULT']),
                buttons: [
                    {
                        text: 'Ok',
                        role: 'confirm',
                    },
                ],
            });
            await alert.present();
            await alert.onDidDismiss();
        }
    }

    async getArtNeutriSearch(searchText: string) {
        const get = await this.common.getArtNeutriSearch(searchText);
        console.log('get', get);
        return get;
    }

    async setModelliArticolo(codmod: string, codart: string) {
        const set = await this.common.setModelliArticolo(codmod, codart);
        console.log('set', set);
    }

    async delModelliArticolo(codmod: string, codart: string) {
        const del = await this.common.delModelliArticolo(codmod, codart);
        console.log('del', del);
    }

    async getAssocModArt(codmod: string) {
        const get = await this.common.getAssocModArt(codmod);
        return get;
    }

    async setAssocModVarOpz(
        codmod: string,
        codvar: string,
        codopz: string,
        opzscope: string,
        opzcontext: string
    ) {
        const set = await this.common.setAssocModVarOpz(
            codmod,
            codvar,
            codopz,
            opzscope,
            opzcontext
        );
        console.log('set', set);
    }
    async getAssocModVarOpz(codmod: string) {
        const get = await this.common.getAssocModVarOpz(codmod);
        return get;
    }

    async delAssocModVarOpz(codmod: string, codvar: string, codopz: string) {
        const del = await this.common.delAssocModVarOpz(codmod, codvar, codopz);
        console.log('del', del);
    }

    async getValModOrd(codmod: string) {
        const get = await this.common.getValModOrd(codmod);
        return get;
    }

    async setValModOrd(ord_payload: any) {
        const set = await this.common.setValModOrd(ord_payload);
        console.log('set', set);
    }

    //* old

    async getModello(cod_mod: string) {
        const modello = await this.common.getModello(cod_mod);
        console.log('get modello', modello);
        return modello;
    }

    async getAttivazioniVarOpzModello(cod_mod: string, codvar: string) {
        const attivazioni = await this.common.getAttivazioniVarOpzModello(
            cod_mod,
            codvar
        );
        console.log('get attivazioni varopz modello', attivazioni);
        return attivazioni;
    }

    async setAttivazioniVarOpz(
        cod_mod: string,
        codvar: string,
        codopz: string
    ) {
        const setAttivazioni = await this.common.setAttivazioniVarOpz(
            cod_mod,
            codvar,
            codopz
        );
        console.log('response set attivazioni var opz modello', setAttivazioni);
    }

    async delAttivazioniVarOpzModello(
        cod_mod: string,
        codvar: string,
        codopz: string
    ) {
        const delAttivazioni = await this.common.delAttivazioniVarOpzModello(
            cod_mod,
            codvar,
            codopz
        );
        console.log('response del attivazioni varopz modello', delAttivazioni);
    }

    async setInitVarModello(cod_mod: string, codvar: string, codopz: string) {
        const setInit = await this.common.setInitVarModello(
            cod_mod,
            codvar,
            codopz
        );
        console.log('response set init var modello', setInit);
    }

    async setArticoloNelModello(cod_modello: string, codart: string) {
        const setArticoloModello = await this.common.setArticoloNelModello(
            cod_modello,
            codart
        );
        console.log('response set articolo nel modello', setArticoloModello);
    }

    async delArticoloNelModello(cod_modello: string, codart: string) {
        const delArticoloModello = await this.common.delArticoloNelModello(
            cod_modello,
            codart
        );
        console.log('response del articolo nel modello', delArticoloModello);
    }

    async getVarianti() {
        const varianti = await this.common.getVarianti();
        console.log('varianti arrivate', varianti);
        return varianti;
    }
}
