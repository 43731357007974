<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>{{this.translate.instant('157')}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-row>
    <ion-col size="12" text-center>
      <form [formGroup]="fg">
        <ion-item mode="ios">
          <ion-input [label]="this.translate.instant('030')" labelPlacement="stacked" type="text" autocomplete
            (focusout)="test()" formControlName="codice" [value]="modalDataResponse"></ion-input>
          <ion-button mode='ios' (click)="ricercaArticoli()"><ion-icon name="search-outline"></ion-icon></ion-button>
        </ion-item>
        <ion-item mode="ios">
          <ion-input [label]="this.translate.instant('098')" labelPlacement="stacked" type="text"
            formControlName="qta"></ion-input>
        </ion-item>
        <ion-item mode="ios">
          <ion-select [label]="this.translate.instant('099')" interface="popover" mode="ios" formControlName="colonna">
            <ion-select-option *ngFor="let colonna of colonne">{{colonna}}</ion-select-option>
            <ion-select-option>MACRO</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item mode="ios">
          <ion-select [label]="this.translate.instant('100')" interface="popover" mode="ios"
            formControlName="operatore">
            <ion-select-option>+</ion-select-option>
            <ion-select-option>-</ion-select-option>
            <ion-select-option>=</ion-select-option>
          </ion-select>
        </ion-item>
      </form>
    </ion-col>
  </ion-row>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="ion-text-center">
    <ion-button mode='ios' type="submit" color="primary"
      (click)="doNuovaRiga()">{{this.translate.instant('123')}}</ion-button>
  </ion-toolbar>
</ion-footer>

<!-- [disabled]="!fg.valid"-->
