<ion-header class="ion-no-border" mode='ios'>
  <ion-toolbar>
    <ion-icon id="backIcon" color="secondary" name="chevron-back-outline" routerDirection="root"
      [routerLink]="['/dashboard']"></ion-icon>
    <ion-title>{{this.translate.instant('182')}}</ion-title>
    <ion-button mode='ios' slot="end" (click)="this.modalCtrl.dismiss()" fill="clear">
      <ion-icon slot="icon-only" name="close-circle-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-item mode="ios">
    <ion-label position="stacked">{{this.translate.instant('187')}}</ion-label>
    <ion-input labelPlacement="stacked" type="file" accept=".txt, .xls, .xlsx"
      (change)="importaFile($event)"></ion-input>
  </ion-item>

  <ion-item mode="ios" *ngIf="this.sceltaListino === true">
    <ion-select interface="popover" multiple="false" placeholder="Seleziona il set in cui importare"
      (ionChange)="selezionaListino($event)">
      <ion-select-option [value]="set" *ngFor="let set of setListini">{{set.CODSET}}</ion-select-option>
    </ion-select>
  </ion-item>

</ion-content>

<ion-footer class="ion-no-border" *ngIf="this.showBtn === true">
  <ion-toolbar class="ion-text-center">
    <ion-button (click)="prosegui()" mode="ios">{{this.translate.instant('160')}}</ion-button>
  </ion-toolbar>
</ion-footer>
