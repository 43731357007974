import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AlertController,
  ModalController,
  ViewWillEnter,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ComposizioniService } from 'src/app/servizi/composizioni.service';

@Component({
  selector: 'app-copia-composizione',
  templateUrl: './copia-composizione.component.html',
  styleUrls: ['./copia-composizione.component.scss'],
})
export class CopiaComposizioneComponent implements ViewWillEnter, OnInit {
  fg: FormGroup;
  codstart: string;

  constructor(
    public translate: TranslateService,
    public modalCtrl: ModalController,
    private composizioniService: ComposizioniService,
    private fb: FormBuilder,
    private alertCtrl: AlertController
  ) {}

  ionViewWillEnter(): void {}

  ngOnInit(): void {
    this.fg = this.fb.group({
      cod_end: ['', Validators.required],
      des_end: ['', Validators.required],
      diml_end: ['', Validators.required],
      dima_end: ['', Validators.required],
      dimp_end: ['', Validators.required],
    });
  }

  async doCopia() {
    let cod_end = this.fg.get('cod_end').value;
    let des_end = this.fg.get('des_end').value;
    let diml_end = this.fg.get('diml_end').value;
    let dima_end = this.fg.get('dima_end').value;
    let dimp_end = this.fg.get('dimp_end').value;

    await this.composizioniService
      .doCopiaComposizione(
        this.codstart,
        cod_end,
        des_end,
        diml_end,
        dima_end,
        dimp_end
      )
      .then(async (response) => {
        const risp = JSON.stringify(response)
          .split(':')
          .pop()
          .replace('}', '')
          .replace('"', '')
          .replace('"', '');
        console.log('response', risp);
        if (risp == '200') {
          //. Se non ci sono errori dismetto
          this.modalCtrl.dismiss();
        } else {
          //. Se ci sono errori li visualizzo e non dismetto
          const alert = await this.alertCtrl.create({
            mode: 'ios',
            header: this.translate.instant('A004'),
            message: this.translate.instant(risp),
            buttons: [
              {
                text: 'Riprova',
                role: 'confirm',
              },
            ],
          });
          await alert.present();
          await alert.onDidDismiss();
        }
      });
  }
}
