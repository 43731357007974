<ion-header mode="ios" class="ion-no-border">
  <ion-toolbar>
    <ion-title>Nuovo listino calcolato per distinta</ion-title>
    <ion-icon style="float: right;" name="close-outline" (click)="modalCtrl.dismiss()"
      *ngIf="permitClosingDialog === true"></ion-icon>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-grid *ngIf="showProgress === true">
    <ion-row>
      <ion-col>
        <ion-progress-bar [value]="progressValue" color="primary" max="100" id="progressor"></ion-progress-bar>
        <!-- i valori vanno da 0 a 1, l'api rimanda da 0 a 100, gestiscila -->
        <span id="percentage">0</span>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid *ngIf="showProgress === false">
    <ion-row>
      <ion-col size="12" text-center>
        <form [formGroup]="fg">
          <ion-item mode="ios">
            <ion-select mode="ios" interface="popover" label="Listino di partenza" labelPlacement="stacked"
              formControlName="LISTINO_START">
              <ion-select-option *ngFor="let l of listini" [value]="l.COD_LISTINO">{{l.COD_LISTINO}}</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item mode="ios">
            <ion-input label="Distinta" labelPlacement="stacked" (click)="openViewerDistinte()"
              [value]="this.disintaDaPassare"></ion-input>
          </ion-item>
          <ion-item mode="ios">
            <ion-select mode="ios" interface="popover" label="Listino di destinazione" labelPlacement="stacked"
              formControlName="LISTINO_END">
              <ion-select-option *ngFor="let l of listini" [value]="l.COD_LISTINO">{{l.COD_LISTINO}}</ion-select-option>
            </ion-select>
          </ion-item>
        </form>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="ion-text-center">
    <ion-button mode='ios' type="submit" [disabled]="!fg.valid && this.disintaDaPassare !== ''"
      (click)="doListinoCalcolatoDistinta()" color="primary">Crea</ion-button>
  </ion-toolbar>
</ion-footer>
