import { Injectable } from '@angular/core';
import { CommonFunctionService } from './common-function.service';

@Injectable({
    providedIn: 'root',
})
export class PersonalizzazioniArbiService {
    constructor(private common: CommonFunctionService) {}

    async getAssociaModelloColonnaArbi() {
        const get = await this.common.getAssociaModelloColonnaArbi();
        return get;
    }

    async doAssociaModelloColonnaArbi(codmod: string, colplist: string) {
        const set = await this.common.doAssociaModelloColonnaArbi(
            codmod,
            colplist
        );
        console.log('risp set', set);
    }
    async delAssociaModelloColonnaArbi(codmod: string, colplist: string) {
        const del = await this.common.delAssociaModelloColonnaArbi(
            codmod,
            colplist
        );
        console.log('risp del', del);
    }

    async doExportRivenditori(cod_listino: string) {
        const doExport = await this.common.doExportRivenditori(cod_listino);
        console.log('result do export', doExport);
    }
}
