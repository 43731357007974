import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { PreferenzeService } from 'src/app/servizi/preferenze.service';

@Component({
    selector: 'app-select-opz-scope',
    templateUrl: './select-opz-scope.component.html',
    styleUrls: ['./select-opz-scope.component.scss'],
})
export class SelectOpzScopeComponent implements OnInit {
    fg: FormGroup;

    activeField: string = '';

    colonne: string[] = [];

    constructor(
        public modalCtrl: ModalController,
        private fb: FormBuilder,
        private ps: PreferenzeService
    ) {}

    ngOnInit() {
        this.fg = this.fb.group({
            opzscope: [''],
            opzcontext: [''],
            codice: [''], // Campo aggiunto per il testo
            colonna: [''], // Campo aggiunto per il select delle colonne
        });
    }

    async getSelect(e: any) {
        console.log('event', e.detail.value);
        let selection = e.detail.value;

        switch (selection) {
            case 'N':
                this.activeField = 'N';
                break;
            case 'D':
                this.activeField = 'D';
                break;
            case 'C':
                await this.getColonne().then(() => {
                    this.activeField = 'C';
                });
                break;
            case 'L':
                this.activeField = 'L';
                break;
        }
    }

    async getColonne() {
        let response = await this.ps.getPreferenzaCatalogo('COLONNE_PRICELIST');
        this.colonne = response.VALORE_PREFERENZA.split(',');
    }

    salva() {
        const opzscope = this.fg.get('opzscope').value;

        const codice = this.fg.get('codice')?.value;
        const colonna = this.fg.get('colonna')?.value;

        //? Switch sui campi

        if (this.activeField === 'C') {
            // Assegna il valore combinato a opzcontext
            this.fg.patchValue({
                opzcontext: `${codice}|${colonna}`,
            });

            console.log(this.fg.value);
            let opzcontext = codice + '|' + colonna;

            this.modalCtrl.dismiss({ opzscope, opzcontext });
        }
    }
}
