<ion-header class="ion-no-border">
    <ion-toolbar>
        <ion-title> Associa modello a colonna</ion-title>
        <ion-icon style="float: right;" color="primary" name="close-outline" (click)="modalCtrl.dismiss()"></ion-icon>
    </ion-toolbar>
</ion-header>

<ion-content>

    <form [formGroup]="fg">
        <ion-item>
            <ion-select label="Seleziona il modello" labelPlacement="stacked" formControlName="codmod"
                interface="popover">
                <ion-select-option *ngFor="let m of modelli" [value]="m.CODMOD">
                    <b>{{ m.CODMOD }}</b> {{ m.DES }}
                </ion-select-option>
            </ion-select>
        </ion-item>
        <ion-item>
            <ion-select label="Seleziona la colonna" labelPlacement="stacked" formControlName="colplist"
                interface="popover">
                <ion-select-option *ngFor="let c of colonne" [value]="c">
                    {{c}}
                </ion-select-option>
            </ion-select>
        </ion-item>
    </form>

</ion-content>

<ion-footer class="ion-no-border">
    <ion-toolbar class="ion-text-center">
        <ion-button color="primary" mode="ios" (click)="doAssociazione()">Crea associazione</ion-button>
    </ion-toolbar>
</ion-footer>
