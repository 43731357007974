<ion-header>
  <ion-toolbar>
    <ion-title>{{this.translate.instant('248')}}</ion-title>
    <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
      <ion-icon slot="icon-only" name="close-circle-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="this.abilitaForm === false">
  <ion-grid class="ion-margin">
    <ion-row class="header-row">
      <ion-col>{{this.translate.instant('249')}}</ion-col>
    </ion-row>
    <ion-row class="colored" *ngFor="let setting of preferenzeInPrint">
      <ion-col size="10" class="padding"><span>{{ setting.COD_PREFERENZA }}</span></ion-col>
      <ion-col><ion-button mode='ios' size="small" color="primary"
          (click)="aggiungi(setting)">{{this.translate.instant('250')}}</ion-button></ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-content *ngIf="this.abilitaForm === true">
  <ion-row>
    <ion-col size="12" text-center>
      <form (keydown.enter)="$event.preventDefault()" [formGroup]="fg">
        <ion-item mode="ios">
          <ion-label>{{this.translate.instant('030')}}</ion-label>
          <ion-input labelPlacement="stacked" readonly>{{ preferenzaSelezionata.COD_PREFERENZA }}</ion-input>
        </ion-item>
        <ion-item mode="ios">
          <ion-label>{{this.translate.instant('246')}}</ion-label>
          <ion-input labelPlacement="stacked" type="text" *ngIf="this.text === true"
            formControlName="valore"></ion-input>
          <ion-textarea *ngIf="this.textarea === true;" rows="15" formControlName="valore"></ion-textarea>
          <ion-select interface="popover" mode="ios" *ngIf="this.timezone === true" formControlName="valore">
            <ion-select-option value="-12">GMT - 12</ion-select-option>
            <ion-select-option value="-11">GMT - 11</ion-select-option>
            <ion-select-option value="-10">GMT - 10</ion-select-option>
            <ion-select-option value="-9">GMT - 9</ion-select-option>
            <ion-select-option value="-8">GMT - 8</ion-select-option>
            <ion-select-option value="-7">GMT - 7</ion-select-option>
            <ion-select-option value="-6">GMT - 6</ion-select-option>
            <ion-select-option value="-5">GMT - 5</ion-select-option>
            <ion-select-option value="-4">GMT - 4</ion-select-option>
            <ion-select-option value="-3">GMT - 3</ion-select-option>
            <ion-select-option value="-2">GMT - 2</ion-select-option>
            <ion-select-option value="-1">GMT - 1</ion-select-option>
            <ion-select-option value="+0">GMT + 0</ion-select-option>
            <ion-select-option value="+1">GMT + 1</ion-select-option>
            <ion-select-option value="+2">GMT + 2</ion-select-option>
            <ion-select-option value="+3">GMT + 3</ion-select-option>
            <ion-select-option value="+4">GMT + 4</ion-select-option>
            <ion-select-option value="+5">GMT + 5</ion-select-option>
            <ion-select-option value="+6">GMT + 6</ion-select-option>
            <ion-select-option value="+7">GMT + 7</ion-select-option>
            <ion-select-option value="+8">GMT + 8</ion-select-option>
            <ion-select-option value="+9">GMT + 9</ion-select-option>
            <ion-select-option value="+10">GMT + 10</ion-select-option>
            <ion-select-option value="+11">GMT + 11</ion-select-option>
            <ion-select-option value="+12">GMT + 12</ion-select-option>
          </ion-select>
          <ion-input labelPlacement="stacked" *ngIf="this.list1 === true" type="text"
            formControlName="valore"></ion-input>
        </ion-item>






      </form>
    </ion-col>
  </ion-row>
</ion-content>
<ion-footer class="ion-no-border" *ngIf="this.abilitaForm === true">
  <ion-toolbar>
    <ion-button mode='ios' [disabled]="!fg.valid" (click)="doAggiungiPreferenza()"
      color="primary">{{this.translate.instant('031')}}</ion-button>
  </ion-toolbar>
</ion-footer>
