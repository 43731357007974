<ion-header class="ion-no-border" mode='ios'>
  <ion-toolbar>
    <ion-icon id="backIcon" color="secondary" name="chevron-back-outline" routerDirection="root"
      [routerLink]="['/dashboard']"></ion-icon>
    <ion-title>Nuova opzione</ion-title>
    <ion-button mode='ios' slot="end" (click)="modalCtrl.dismiss()" fill="clear">
      <ion-icon slot="icon-only" name="close-circle-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="fg">

    <ion-item mode="ios">
      <ion-input label="Codice" labelPlacement="stacked" formControlName="CODOPZ"></ion-input>
    </ion-item>
    <ion-item mode="ios">
      <ion-input label="Descrizione" labelPlacement="stacked" formControlName="DESOPZ"></ion-input>
    </ion-item>
    <ion-item mode="ios">
      <ion-input label="Traduzione gestionale" labelPlacement="stacked" formControlName="TRADGEST"></ion-input>
    </ion-item>
    <!-- <ion-item mode="ios">
      <ion-select label="Materiale" formControlName="MATERIAL">
        <ion-select-option value="LAC">Laccato opaco</ion-select-option>
        <ion-select-option value="LAC">Laccato lucido</ion-select-option>
        <ion-select-option value="CHR">Metallo cromato</ion-select-option>
        <ion-select-option value="MET">Metallo opaco</ion-select-option>
        <ion-select-option value="WOD">Legno</ion-select-option>
        <ion-select-option value="MAR">Marmo/granito</ion-select-option>
        <ion-select-option value="TES">Tessuto</ion-select-option>
        <ion-select-option value="PEL">Pelle</ion-select-option>
        <ion-select-option value="VTR">Vetro</ion-select-option>
        <ion-select-option value="COL">Colore</ion-select-option>
      </ion-select>
    </ion-item> -->

    <ion-item mode="ios">
      <ion-input label="Carica texture" labelPlacement="stacked" accept="image/jpeg, image/png" [value]="textureName"
        readonly></ion-input>
      <input type="file" #fileInput (change)="onTextureSelected($event)" accept="image/jpeg, image/png" hidden>
      <ion-button (click)="fileInput.click()" mode="ios" size="small">Carica Immagine</ion-button>
    </ion-item>
    <ion-item mode="ios">
      <ion-img [src]="this.srcImage" *ngIf="this.srcImage !== ''" class="texture-img" (click)="openImage()"></ion-img>
    </ion-item>
    <!-- ? in base alla selezione esce una gallery o un picker, nel momento in cui seleziono passo il dato in 'DATA_MATERIAL'-->

  </form>
</ion-content>

<ion-footer class="ion-no-border ion-text-center">
  <ion-toolbar>
    <ion-button mode='ios' type="submit" color="primary" [disabled]="!fg.valid"
      (click)="doNuovaOpzione()">Prosegui</ion-button>
  </ion-toolbar>
</ion-footer>
