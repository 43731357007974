<ion-header class="ion-no-border" mode='ios'>
  <ion-toolbar>
    <ion-icon id="backIcon" color="secondary" name="chevron-back-outline" routerDirection="root"
      [routerLink]="['/dashboard']"></ion-icon>
    <ion-title>Invita</ion-title>
    <ion-button mode='ios' slot="end" (click)="close()" fill="clear">
      <ion-icon slot="icon-only" name="close-circle-outline" color="primary"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item mode="ios">
      <ion-label>mail o ID invito</ion-label>
      <ion-input labelPlacement="stacked" (keydown.enter)="searchUsers()" placeholder="cerca..."
        [(ngModel)]="searchQuery"></ion-input>
      <ion-icon name="search-outline" (click)="searchUsers()"></ion-icon>
    </ion-item>
  </ion-list>

  <ion-grid *ngIf="showResults === true;">
    <ion-row *ngFor="let result of results">
      <ion-col>
        <ion-card (click)="userSelected(result)">
          <ion-card-header>{{result.NOME + ' ' + result.COGNOME}}</ion-card-header>
          <ion-card-subtitle>{{result.EMAIL}}</ion-card-subtitle>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
  <!-- <ion-row>
    <ion-col>
      <form [formGroup]="formInvitaUtente" (ngSubmit)="doInvitaUtente()">

        <ion-button mode='ios' type="submit" color="primary" [disabled]="!formInvitaUtente.valid">Invita</ion-button>
      </form>
    </ion-col>
  </ion-row> -->
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="ion-text-center">
    <ion-button mode="ios" color="primary" (click)="creaUtente()">
      <img class="icons" src="../../../assets/svg/invite-01.svg">
      Aggiungi manualmente
    </ion-button>
  </ion-toolbar>
</ion-footer>
